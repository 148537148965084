import { useState } from "react";
import FadeLeftWhenVisible from "../components/Fadeleft";
import FadeRightWhenVisible from "../components/Faderight";
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from "../components/ErrorFallback";
import emailjs from "@emailjs/browser";
import {ReactComponent as Loader} from "../assets/loader.svg";

function FormSuccess({success}){

  return(
    <div className="">
      <h4 className="submit-msg">Thank You For Reaching Out to Us!!, We'll get back to you as soon as possible</h4>
      <div className="center"><button onClick={success} className="abtn btn">OK</button></div>
      
    </div>
  )
}
function Contact(){
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const success = () => {
    setIsSubmitted(prev => !prev);
  };
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    phone_number: "",
    company_name: "",
    message: ""
  });
  const { from_name, reply_to, phone_number, company_name, message } = formData;
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };
  const onSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    formData["to_name"] = "247COMCARE";

    const YOUR_SERVICE_ID = "service_lqj88k5";
    const YOUR_TEMPLATE_ID = "247comcare_rity7ks";
    const YOUR_PUBLIC_KEY = "ZTxRbsirc1MJiUsQ9";

    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, formData, YOUR_PUBLIC_KEY).then(
      () => {
        setIsSubmitted(true)
        setFormData({
          from_name: "",
          reply_to: "",
          phone_number: "",
          company_name: "",
          message: ""
        });
        console.log("Email Sent");
      },
      (error) => {
        console.log(JSON.stringify(error));
      });
    console.log(formData);

    setTimeout(() => {
      setLoading(false)
 }, 3000)
  };
        

  if (loading) return <div className="loader" ><Loader className="load"/></div>;
    return(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <section id="contact">
        
        <article className="container contact-us flex">
        
          <div className="contact-us-txt">
          <FadeLeftWhenVisible>
            <h2>
              Get <br /> in touch
            </h2>
            <p className="p1">
              Contact us to find out how the 24/7 team could help you today.
            </p>
            </FadeLeftWhenVisible>
          </div>
          
          

          <div className="contact-us-form">
            {!isSubmitted &&
            <form method="POST" data-netlify="true"onSubmit={onSubmit}>
              <FadeRightWhenVisible>
             <input type="hidden" name="form-name" value="contact" />
              <input
              onChange={handleChange}
                id="from_name"
                type="text"
                name="from_name"
                value={from_name}
                className="fullname input"
                placeholder="Full Name"
                required
              />
  
              <input
                onChange={handleChange}
                id="reply_to"
                type="text"
                name="reply_to"
                value={reply_to}
                className="email input"
                placeholder="Your Email"
                minLength="8"
                required
              />
  
              <input
                onChange={handleChange}
                id="phone_number"
                type="phone_number"
                name="phone_number"
                value={phone_number}
                // pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}"
                required
                placeholder="Your Phone Number"
                className="number input"
              />
  
              <input
                onChange={handleChange}
                id="company_name"
                type="text"
                name="company_name"
                value={company_name}
                placeholder="Company Name"
                className="subject input"
                required
              />
  
              <textarea
                onChange={handleChange}
                id="message"
                type="text"
                name="message"
                value={message}
                placeholder="Your Message"
                className="message input"
                rows="4"
                cols="16"
                required
              ></textarea>
  
              <div className="check-box">
                <p>
                  <input type="checkbox" required /> By providing your details you
                  consent to the collection and processing of your personal data
                  in line with the 24/7
                  <a href="#g" className="privacy">
                   
                    Privacy Policy
                  </a>
                </p>
              </div>
  
              <button className="contact-btn btn" type="submit">
                Send
              </button>
              </FadeRightWhenVisible>
            </form>}
           
            {isSubmitted ? <FormSuccess success={success}/> : null}
          </div>
          
        </article>
      </section>
       </ErrorBoundary>
    )
}


export default Contact