import { easeOut, motion} from "framer-motion"

function FadeLeftWhenVisible({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.9, ease:easeOut}}
        variants={{
          visible: { opacity: 1, x: 0},
          hidden: { opacity: 0, x: '-10vw'}
        }}
      >
        {children}
      </motion.div>
    );
  }
  export default FadeLeftWhenVisible;