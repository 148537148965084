import FadeInWhenVisible from "../components/Fade";
import serviceicon1 from "../assets/walkie.png";
import serviceicon2 from "../assets/customer.png";
import serviceicon3 from "../assets/headphone.png";
import serviceicon4 from "../assets/coworking.png";
import serviceicon5 from "../assets/mail.png";
import serviceicon6 from "../assets/inoutbound.png";

function Service() {
  return (
    <section id="services" className="service flex flex-col">
      <FadeInWhenVisible>
        <div className="service-text flex flex-col">
          <p className="sv">our services</p>
          <h2 className="text-2xl font-bold">What Service We Offer</h2>
        </div>
      </FadeInWhenVisible>

      <div className="service-card-cont">
        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">01</h2>
            <img
              src={serviceicon1}
              className="service-icon"
              alt="service-icon"
            />
          </div>
          <h4 className="font-bold">Telco VAS</h4>
          <p>
            We specialize in value-added services and have expertise in
            integrating with leading aggregators and VAS providers, as well as
            creating innovative services.
          </p>
        </div>
        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">02</h2>
            <img
              src={serviceicon2}
              className="service-icon"
              alt="service-icon"
            />
          </div>
          <h4 className="font-bold">Customer/Technical Supports</h4>
          <p>
            We ensure that your customers receive individualised customer care
            and technical assistance through professional contact, quick
            resolution, delivering value from every call.
          </p>
        </div>
        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">03</h2>
            <img
              src={serviceicon3}
              className="service-icon"
              alt="service-icon"
            />
          </div>
          <h4 className="font-bold">Telemarketing Services</h4>
          <p>
            We can assist you in expanding your marketing strategies and
            increase brand exposure with a more accurate method.
          </p>
        </div>

        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">04</h2>
            <img
              src={serviceicon4}
              className="service-icon"
              alt="service-icon"
            />
          </div>

          <h4 className="font-bold">Back Office Support Teams</h4>
          <p>
            With our backoffice services, including bookkeeping, accounting,
            data entry, administrative activities, and more, get ready to up
            your efficiency and say no to missed deadlines and subpar
            production. .
          </p>
        </div>

        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">05</h2>
            <img
              src={serviceicon5}
              className="service-icon"
              alt="service-icon"
            />
          </div>

          <h4 className="font-bold">Email/Chat Service</h4>
          <p>
            We have all you need to develop your brand's image. Our email and
            chat support specialists are dedicated to going above and beyond to
            enhance the experience for your customers.
          </p>
        </div>

        <div className="service-card flex-col">
          <div>
            <h2 className="text-3xl font-bold mb-2">06</h2>
            <img
              src={serviceicon6}
              className="service-icon"
              alt="service-icon"
            />
          </div>
          <div>
            <h4 className="font-bold">Inbound and Outbound Services</h4>
            <p>
              We dejoy working with discerning clients, people for whom qualuty,
              service, integrity & aesthetics.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Service;
