import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import FadeInWhenVisible from "../components/Fadeleft";
import carouselicon1 from "../assets/smartphone.png";
import carouselicon2 from "../assets/station.png";
import carouselicon3 from "../assets/travel.png";
import carouselicon4 from "../assets/bank.png";
import carouselicon5 from "../assets/healthcare.png";
import carouselicon6 from "../assets/shopping.png";
import carouselicon7 from "../assets/hospitality.png";

const slides = [
  {
    title: "Telco VAS Services",
    image: carouselicon1,
  },
  {
    title: "Telecommunications",
    image: carouselicon2,
  },
  {
    title: "Aviation and Travel",
    image: carouselicon3,
  },
  {
    title: "Finance and Banking",
    image: carouselicon4,
  },
  {
    title: "Healthcare",
    image: carouselicon5,
  },
  {
    title: "E-Commerce and Retail Industries",
    image: carouselicon6,
  },
  {
    title: "Hospitality",
    image: carouselicon7,
  },
];
function Carousel() {
  return (
    <div className="carousel-card">
      <FadeInWhenVisible>
        <ReactCarousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          swipeable={true}
          emulateTouch={true}
          autoPlay={true}
          interval={3000}
          infiniteLoop={true}
        >
          {slides.map((slide) => (
            <div key={slide.title} className="flex flex-col">
              <div className="image-wrapper">
                <img
                  className="carousel-image"
                  src={slide.image}
                  alt="carouselimage"
                />
              </div>
              <h2 className="carousel-title text-2l">{slide.title}</h2>
            </div>
          ))}
        </ReactCarousel>
      </FadeInWhenVisible>
    </div>
  );
}
export default Carousel;
