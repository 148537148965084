import { useState } from "react";
import logo from "../assets/logo.png"
import "./Navbar.css"
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavBar(){
    const [navOpen, setNavOpen] = useState(false);
    
    const openNav = ()=>{
        setNavOpen(!navOpen)
    }


    return(
        <nav className="navbar flex">
            <div className="logo">
                <AnchorLink href="#home" >
                    <img src={logo} className="logo" alt="247comcare logo" />
                </AnchorLink>
            </div>

            <div className={`nav-menu ${navOpen && "open"}`}>
                <AnchorLink href="#home" className="menu">Home</AnchorLink>
                <AnchorLink href="#about" className="menu">About</AnchorLink>
                <AnchorLink href="#services" className="menu">Services</AnchorLink>
                <AnchorLink href="#contact" className="menu">Contact Us</AnchorLink>
            </div>
            <div className={`nav-toggle ${navOpen && "open"}`} onClick={openNav}>
                <div className="bar"></div>
            </div>
        </nav>
    )
}
export default NavBar;