import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";


function Modal({openModal, showModal}) {
  return (
    <div className="modal">
      {!showModal && (
        <div className="modal-content text-left">
            <p className="sv font-semibold">Why Us</p>
            <ol className="list-decimal list-inside">
              <li>
                A well trained and passionate team: We provide deep and scalable customer support in any required time zone and a dedicated team, 
                with native and English-speaking support representatives. 
              </li>
              <li>
                Great Quality Assurance: We provide call recordings and texts for our clients through our robust CRM that can be integrated into your CRM.
              </li>
              <li>
              Affordable than when you do it yourself: This is our core business and we have the economics of scale on our side. 
              </li>
              <li>
              Customised Services: We assess your customer service volumes, protocols and complexity and set up a team to match your service requirements. 
              </li>
            </ol>

            <div className="flex mb-6">

              <AnchorLink href='#contact'><button id="cta-btn" className="abtn btn">Make Appointment</button></AnchorLink>
              <button className="abtn btn" onClick={openModal}> close</button>
            </div>
        </div>
          
          
      )}
    </div>
  );
}

export default Modal;
