import { useState } from "react";
import { motion } from "framer-motion"
import FadeInWhenVisible from "../components/Fade";
import FadeLeftWhenVisible from "../components/Fadeleft";
import FadeRightWhenVisible from "../components/Faderight";
import aboutimage from "../assets/support.png";
import Modal from "./Modal";

function About() {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <section id="about">
      <FadeInWhenVisible>
        <article className=" service-text flex flex-col text-center">
            <p className="sv pb-2">About Us</p>
            <h2 className="text-2xl font-bold">
              We Are The Best Customer Service For Your Business
            </h2>
        </article>
      </FadeInWhenVisible>
      <article className="about md:grid md:gap-8 md:grid-cols-2 grid">
        <FadeLeftWhenVisible>
        <div className="about-image">
          <img
            src={aboutimage}
            className="about-img"
            alt="support team working together illustration"
          />
        </div>
        </FadeLeftWhenVisible>
        {!showModal && (
          <FadeRightWhenVisible>
          <article className="about-text">
            <p>
              247 Comcare provides customized Customer Service and Back Office
              for industry leading startups and modern companies.
            </p>
            <p>
              We provide all round customer support for leading companies in
              multiple languages. We handle inbound and outbound customer
              requests at a level worthy of your customers.
            </p>
            <motion.button whileHover={{scale:1.1}} className="abtn btn mb-6" onClick={openModal}>
              Learn More About Us
            </motion.button>
          </article>
          </FadeRightWhenVisible>
        )}
        {showModal ? <Modal openModal={openModal} /> : null}
      </article>
    </section>
  );
}
export default About;
