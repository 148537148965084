import { motion } from "framer-motion";
import FadeLeftWhenVisible from "../components/Fadeleft";
import FadeRightWhenVisible from "../components/Faderight";
import AnchorLink from "react-anchor-link-smooth-scroll";
import NavBar from "../components/Navbar";
import About from "../components/About";
import Carousel from "../components/Carousel";
import Contact from "../components/Contact";
import logo from "../assets/COMCARE.png";
import headerimage from "../assets/customer-support-background.jpeg";
import phone from "../assets/phone-call.png";
import address from "../assets/location.png";
import mail from "../assets/email.png";
import Service from "../components/Service";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

function Home() {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <main id="home" className="container main">
          <NavBar />

          <header className="header md:grid md:gap-8 md:grid-cols-2 grid">
            <FadeLeftWhenVisible>
              <article className="header-text md:col-start-1">
                <h1>
                  Find The Right Customer Service Professional For Your Business
                </h1>
                {/* <p>
                  247 Comcare offers personalized customer service and efficient
                  back-office solutions tailored to meet the needs of thriving
                  startups and contemporary enterprises. Our primary goal is to
                  offer great value-added services while using our experience in
                  seamless integration with industry-leading aggregators and VAS
                  providers.
                </p> */}
                <ReactCarousel
                  autoPlay={true}
                  interval={4000}
                  infiniteLoop={true}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {slides.map((slide) => (
                    <p key={slide.title} className="text-left">
                      {slide.title}
                    </p>
                  ))}
                </ReactCarousel>
                {/* <p>
                  Our primary goal is to offer great value-added services while
                  using our experience in seamless integration with
                  industry-leading aggregators and VAS providers.
                </p> */}
                <AnchorLink href="#contact">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    className="btn header-btn"
                  >
                    Get Started
                  </motion.button>
                </AnchorLink>
              </article>
            </FadeLeftWhenVisible>
            <FadeRightWhenVisible>
              <article className="header-image">
                <img
                  src={headerimage}
                  className="header-img"
                  alt=" support woman wih headphone working on a computer"
                />
              </article>
            </FadeRightWhenVisible>
          </header>

          <About />

          <Service />
        </main>

        <section className="customer-section flex flex-col">
          <h2 className="text-2xl font-bold mt-10">Area Of Expertise</h2>
          <Carousel />
        </section>
        <Contact />

        <footer className="footer">
          <article className="container">
            <article className="grid lg:grid-cols-5 gap-3 text-[#bbbbbb]">
              <article className="footer-col col-span-2">
                <AnchorLink href="#home">
                  <img
                    className="footer-logo"
                    src={logo}
                    alt="247comcare logo"
                  />
                </AnchorLink>
                <ul>
                  <li>
                    We offer comprehensive customer support in multiple
                    languages to top companies. We also specialize in providing
                    value-added services and integrating seamlessly with leading
                    aggregators and VAS providers.
                  </li>
                  <li className="foot-li">
                    <img
                      className="footer-icon"
                      src={address}
                      alt="location icon"
                    />{" "}
                    <p className="ml-3">Lagos Nigeria</p>{" "}
                  </li>
                  <li className="foot-li">
                    <img className="footer-icon" src={mail} alt="mail icon" />{" "}
                    <p className="ml-3">info@247comcare.com</p>{" "}
                  </li>
                  <li className="foot-li">
                    <img className="footer-icon" src={phone} alt="phone icon" />{" "}
                    <p className="ml-3">+2347012345678</p>
                  </li>
                </ul>
              </article>
              <article className="footer-col">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a href="#g">About us</a>
                  </li>
                  <li>
                    <a href="#g">Services</a>
                  </li>
                  <li>
                    <a href="#g">Contact Us</a>
                  </li>
                </ul>
              </article>
              <article className="footer-col">
                <h4>Resources</h4>
                <ul>
                  <li>FAQs</li>
                  <li>Privacy Policy</li>
                  <li>Terms & Conditions</li>
                </ul>
              </article>

              <article className="footer-col">
                <h4>follow us</h4>
                <article className="social-links">
                  <a href="#g">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#g">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#g">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#g">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </article>
              </article>
            </article>
          </article>
        </footer>
        <p className="text-center text-sm">
          © Copyright 2023 247Comcare. All rights reserved.
        </p>
      </ErrorBoundary>
    </>
  );
}
export default Home;

const slides = [
  {
    title:
      "247 Comcare offers personalized customer service and efficient back-office solutions tailored to meet the needs of thriving startups and contemporary enterprises.",
  },
  {
    title:
      "Our primary goal is to offer great value-added services while using our experience in seamless integration withindustry-leading aggregators and VAS providers",
  },
];
